import { convertDateToMonth, convertSnakeToCamel } from "@/utils/utilities.js";
import { getSelectValue, getSelectText } from "@/utils/selectUtility.js";
import moment from "moment";

import FormsAuthLogin from "@/forms/authlogin.js";
import FormsAuthSignup from "@/forms/authsignup.js";
import FormsAuthResend from "@/forms/authresend.js";
import FormsAuthReset from "@/forms/authreset.js";
import FormsAuthActivation from "@/forms/authactivation.js";
import FormsAuthResetPassword from "@/forms/authresetpassword.js";

import FormsCustomer from "@/forms/customer.js";
import FormsInvoiceAddress from "@/forms/invoiceaddress.js";
import FormsCustomerBank from "@/forms/customerbank.js";
import FormsPlan from "@/forms/plan.js";
import FormsOption from "@/forms/option.js";
import FormsTaxRate from "@/forms/taxrate.js";
import FormsSupportRate from "@/forms/supportrate.js";
import FormsCoinRate from "@/forms/coinrate.js";
import FormsSetPassword from "@/forms/setpassword.js";
import FormsSetEmail from "@/forms/setemail.js";
import FormsCoupon from "@/forms/coupon.js";
import FormsBrother from "@/forms/brother.js";
import FormsInformation from "@/forms/information.js";
import FormsLogOperation from "@/forms/logoperation.js";
import FormsContract from "@/forms/contract.js";
import FormsCoinCharge from "@/forms/coincharge.js";
import FormsCoinChargeDelete from "@/forms/coinchargedelete.js";
import FormsContractCancel from "@/forms/contractcancel.js";
import FormsContractCancelReception from "@/forms/contractcancelreception.js";
import FormsContractCancelSetting from "@/forms/contractcancelsetting.js";
import FormsContractCancelRelease from "@/forms/contractcancelrelease.js";
import FormsContractCancelDelete from "@/forms/contractcanceldelete.js";
import FormsContractFlow from "@/forms/contractflow.js";
import FormsContractHistory from "@/forms/contracthistory.js";
import FormsContractReception from "@/forms/contractreception.js";
import FormsContractInformation from "@/forms/contractinformation.js";
import FormsContractInformationBrother from "@/forms/contractinformationbrother.js";
import FormsContractIncentive from "@/forms/contractincentive.js";
import FormsContractRenewalTool from "@/forms/contractrenewaltool.js";
import FormsContractRenewalRelease from "@/forms/contractrenewalrelease.js";
import FormsContractRenewalSingle from "@/forms/contractrenewalsingle.js";
import FormsContractSetting from "@/forms/contractsetting.js";
import FormsContractSite from "@/forms/contractsite.js";
import FormsContractSiteCancel from "@/forms/contractsitecancel.js";
import FormsContractRelease from "@/forms/contractrelease.js";
import FormsContractDelete from "@/forms/contractdelete.js";
import FormsCustomerCoin from "@/forms/customercoin.js";
import FormsManagementCoin from "@/forms/managementcoin.js";
import FormsManagementCoinBrother from "@/forms/managementcoinbrother.js";
import FormsInvoiceAdjustment from "@/forms/invoiceadjustment.js";
import FormsInvoiceAdjustmentTool from "@/forms/invoiceadjustmenttool.js";
import FormsInvoiceGroup from "@/forms/invoicegroup.js";
import FormsInvoiceHeader from "@/forms/invoiceheader.js";
import FormsInvoiceReportDetail from "@/forms/invoicereportdetail.js";
import FormsPaymentAdjustment from "@/forms/paymentadjustment.js";
import FormsPaymentAdjustmentTool from "@/forms/paymentadjustmenttool.js";
import FormsPaymentGroup from "@/forms/paymentgroup.js";
import FormsPaymentHeader from "@/forms/paymentheader.js";
import FormsPaymentByUser from "@/forms/paymentbyuser.js";
import FormsPaymentDetail from "@/forms/paymentdetail.js";
import FormsSupport from "@/forms/support.js";
import FormsSupportInfo from "@/forms/supportinfo.js";
import FormsSupportHandle from "@/forms/supporthandle.js";
import FormsStructure from "@/forms/structure.js";
import FormsUser from "@/forms/user.js";
import FormsIncentive from "@/forms/incentive.js";
import FormsCustomerSetting from "@/forms/customersetting.js";
import FormsClient from "@/forms/client.js";
import FormsTransferPlan from "@/forms/transferplan.js";
import FormsTransferOption from "@/forms/transferoption.js";
import FormsTransferCoin from "@/forms/transfercoin.js";
import FormsTransferDisconnect from "@/forms/transferdisconnect.js";
import FormsBrotherDisconnect from "@/forms/brotherdisconnect.js";
import FormsBrotherCustomer from "@/forms/brothercustomer.js";
import FormsBrotherIncentive from "@/forms/brotherincentive.js";

const setAuthFormDict = () => {
  return {
    login: FormsAuthLogin,
    signup: FormsAuthSignup,
    resend: FormsAuthResend,
    reset: FormsAuthReset,
  };
};

const setFormDict = () => {
  return {
    customer: FormsCustomer,
    invoiceaddress: FormsInvoiceAddress,
    customerbank: FormsCustomerBank,
    plan: FormsPlan,
    option: FormsOption,
    taxrate: FormsTaxRate,
    supportrate: FormsSupportRate,
    coinrate: FormsCoinRate,
    coupon: FormsCoupon,
    brother: FormsBrother,
    information: FormsInformation,
    logoperation: FormsLogOperation,
    contract: FormsContract,
    contractcancel: FormsContractCancel,
    contractcancelreception: FormsContractCancelReception,
    contractcancelsetting: FormsContractCancelSetting,
    contractcancelrelease: FormsContractCancelRelease,
    contractcanceldelete: FormsContractCancelDelete,
    contractflow: FormsContractFlow,
    contracthistory: FormsContractHistory,
    contractreception: FormsContractReception,
    contractinformation: FormsContractInformation,
    contractinformationbrother: FormsContractInformationBrother,
    contractincentive: FormsContractIncentive,
    contractrenewaltool: FormsContractRenewalTool,
    contractrenewalrelease: FormsContractRenewalRelease,
    contractrenewalsingle: FormsContractRenewalSingle,
    contractsetting: FormsContractSetting,
    contractsite: FormsContractSite,
    contractsitecancel: FormsContractSiteCancel,
    contractrelease: FormsContractRelease,
    contractdelete: FormsContractDelete,
    invoiceadjustment: FormsInvoiceAdjustment,
    invoiceadjustmenttool: FormsInvoiceAdjustmentTool,
    coincharge: FormsCoinCharge,
    coinchargedelete: FormsCoinChargeDelete,
    customercoin: FormsCustomerCoin,
    managementcoin: FormsManagementCoin,
    managementcoinbrother: FormsManagementCoinBrother,
    invoicegroup: FormsInvoiceGroup,
    invoiceheader: FormsInvoiceHeader,
    invoicereportdetail: FormsInvoiceReportDetail,
    paymentadjustment: FormsPaymentAdjustment,
    paymentadjustmenttool: FormsPaymentAdjustmentTool,
    paymentgroup: FormsPaymentGroup,
    paymentheader: FormsPaymentHeader,
    paymentbyuser: FormsPaymentByUser,
    paymentdetail: FormsPaymentDetail,
    support: FormsSupport,
    supportinfo: FormsSupportInfo,
    supporthandle: FormsSupportHandle,
    structure: FormsStructure,
    user: FormsUser,
    incentive: FormsIncentive,
    customersetting: FormsCustomerSetting,
    client: FormsClient,
    transferplan: FormsTransferPlan,
    transferoption: FormsTransferOption,
    transfercoin: FormsTransferCoin,
    transferdisconnect: FormsTransferDisconnect,
    brotherdisconnect: FormsBrotherDisconnect,
    brothercustomer: FormsBrotherCustomer,
    brotherincentive: FormsBrotherIncentive,
  };
};

const getFormAuthObject = (type) => {
  const authFormDict = setAuthFormDict();
  if (authFormDict[type]) {
    return authFormDict[type];
  }
  if (type === "activation") {
    return FormsAuthActivation;
  } else if (type === "resetpassword") {
    return FormsAuthResetPassword;
  }
  return false;
};

const getFormObject = (type) => {
  const formDict = setFormDict();
  if (formDict[type]) {
    const res = formDict[type];
    if (!Reflect.has(res, "tooltipText")) {
      res["tooltipText"] = [];
    }
    if (!Reflect.has(res, "headers")) {
      res["headers"] = [];
    }
    return res;
  }
  if (type === "setpassword") {
    return FormsSetPassword;
  } else if (type === "setemail") {
    return FormsSetEmail;
  }
  return false;
};

const settingDictionaryForms = (forms, isCsv = false) => {
  const dict = {};
  for (const key in forms) {
    if (isCsv && forms[key]["type"] === "space") {
      continue;
    }
    const name = convertSnakeToCamel(forms[key]["column"]);
    // 各要素にvalueを初期設定する
    forms[key]["value"] = "";
    if (forms[key]["type"] === "select" && forms[key]["default"]) {
      forms[key]["value"] = getSelectValue(
        forms[key]["default"],
        forms[key]["items"]
      );
    }
    dict[name] = forms[key];
  }
  return dict;
};

const setResponseApplyFormsDict = (data, forms) => {
  for (const key in forms) {
    forms[key]["value"] = "";
    if (forms[key]["column"] in data) {
      if (forms[key]["type"].indexOf("select") === 0) {
        // セレクタの値補間
        forms[key]["value"] = getSelectText(
          data[forms[key]["column"]],
          forms[key]["items"]
        );
      } else {
        forms[key]["value"] = data[forms[key]["column"]];
      }
    }
  }
  return forms;
};

/*
 * apiアドレスのパラメータ作成
 */
const getApiParameters = (
  formObject,
  filterColumn,
  filterId,
  isSearchSelect,
  searchSelectValue,
  isSearchSubSelect,
  searchSubSelectValue,
  searchDateFromValue,
  searchDateToValue
) => {
  let res = "";

  // form指定のリストパラメータ
  if (formObject.setting.apiListParam) {
    res += (res.length === 0 ? "" : "&") + formObject.setting.apiListParam;
  }

  // フィルターセットのパラメータ
  if (
    filterColumn.value &&
    filterColumn.value !== "" &&
    filterId.value &&
    filterId.value !== ""
  ) {
    res +=
      (res.length === 0 ? "" : "&") + filterColumn.value + "=" + filterId.value;
  }

  // セレクトサーチのパラメータ
  if (isSearchSelect.value && searchSelectValue.value !== -1) {
    if (
      formObject.searchSelect.filter &&
      formObject.searchSelect.filter[searchSelectValue.value]
    ) {
      // 検索フィルター設定（views.pyでフィルタセットの作成が必要）
      const filterData =
        formObject.searchSelect.filter[searchSelectValue.value];
      res +=
        (res.length === 0 ? "" : "&") +
        formObject.searchSelect.column +
        "__" +
        filterData["formula"] +
        "=" +
        filterData["value"];
    } else {
      // ダイレクト検索
      res +=
        (res.length === 0 ? "" : "&") +
        formObject.searchSelect.column +
        "=" +
        searchSelectValue.value;
    }
  }

  // セレクトサーチSubのパラメータ
  if (isSearchSubSelect.value && searchSubSelectValue.value !== -1) {
    if (
      formObject.searchSubSelect.filter &&
      formObject.searchSubSelect.filter[searchSubSelectValue.value]
    ) {
      // 検索フィルター設定（views.pyでフィルタセットの作成が必要）
      const filterSubData =
        formObject.searchSubSelect.filter[searchSubSelectValue.value];
      res +=
        (res.length === 0 ? "" : "&") +
        formObject.searchSubSelect.column +
        "__" +
        filterSubData["formula"] +
        "=" +
        filterSubData["value"];
    } else {
      // ダイレクト検索
      res +=
        (res.length === 0 ? "" : "&") +
        formObject.searchSubSelect.column +
        "=" +
        searchSubSelectValue.value;
    }
  }

  // 日付検索フィルター（From）のパラメータ
  if (searchDateFromValue.value && searchDateFromValue.value !== "") {
    res +=
      (res.length === 0 ? "" : "&") +
      formObject.searchDateFrom.column +
      "__" +
      formObject.searchDateFrom.formula +
      "=" +
      searchDateFromValue.value;
  }

  // 日付検索フィルター（To）のパラメータ
  if (searchDateToValue.value && searchDateToValue.value !== "") {
    res +=
      (res.length === 0 ? "" : "&") +
      formObject.searchDateTo.column +
      "__" +
      formObject.searchDateTo.formula +
      "=" +
      searchDateToValue.value;
  }

  return res;
};

/*
 * apiで取得したデータをformsにセット
 */
const setApiToFormsData = (resArr, forms) => {
  const resList = [];

  // apiで取得したデータをセットする
  for (const key in forms) {
    const label = forms[key].label;
    const column = forms[key].column;
    const type = forms[key].type;
    const items = forms[key].items;

    let value = resArr[column];
    if (column !== undefined && column.indexOf("___") > -1) {
      const colList = column.split("___");
      let isFirst = true;
      let colData;
      for (const key of colList) {
        if (isFirst) {
          colData = resArr[key];
          isFirst = false;
        } else {
          colData = colData[key];
        }
      }
      value = colData;
    }

    // スペーサーはcontinue
    if (column === "space") {
      continue;
    }

    if (value !== undefined) {
      if (type.indexOf("select") === 0) {
        let isValue = false;
        if (type === "selectTaxRateValue") {
          isValue = true;
        }
        // セレクタの値補間
        const tempValue = getSelectValue(value, items, isValue);
        forms[key].value = tempValue;
        if (!isValue) {
          value = tempValue["text"];
        }
      } else if (type === "dateMonthFirst") {
        // 月度選択（月初）の値補間
        forms[key].value = convertDateToMonth(value);
      } else if (type === "dateMonthEnd") {
        // 月度選択（月末）の値補間
        forms[key].value = convertDateToMonth(value);
      } else if (type === "datetime") {
        forms[key].value = moment(value).format("YYYY-MM-DD HH:mm:ss");
      } else {
        forms[key].value = value;
      }
    }

    resList.push({
      label: label,
      value: value,
    });
  }

  return { forms: forms, list: resList };
};

/*
 * apiで取得したデータをリスト表示用のデータに変換
 */
const setApiToListData = (resArr, columns) => {
  const listData = [];
  const listKey = [];
  let count = 0;

  // apiで取得したデータをdataにセットする
  if (resArr) {
    for (const row of resArr) {
      const line = {};
      for (const col of columns) {
        // 値取得
        let colName = col.value;
        let displayValue = null;
        if (colName === "data-table-expand" && col.expandContent) {
          line["expand_contents"] = Object.assign(row["contract"], row);
        }
        if (col.column) {
          colName = col.column;
        }
        displayValue = row[colName];

        // JOINデータ取得
        if (colName.indexOf("___") > -1) {
          const colList = colName.split("___");
          let isFirst = true;
          let colData;
          for (const key of colList) {
            if (isFirst) {
              if (typeof row === "object" && key in row) {
                colData = row[key];
              } else {
                colData = "***** None *****";
              }
              isFirst = false;
            } else if (colData !== null) {
              if (typeof colData === "object" && key in colData) {
                colData = colData[key];
              } else {
                colData = "***** None *****";
              }
            } else {
              colData = "";
            }
          }
          displayValue = colData;
        }

        // データ整形
        if (displayValue !== undefined) {
          if (col.type === "select") {
            // セレクタ表示
            displayValue = getSelectValue(displayValue, col.items).text;
          } else if (col.type === "number") {
            // カンマ区切り表示
            if (displayValue && displayValue !== null) {
              displayValue = displayValue.toLocaleString();
            } else {
              displayValue = 0;
            }
          } else if (col.type === "date") {
            displayValue = moment(displayValue);
          } else if (col.type === "datetime") {
            displayValue = moment(displayValue).format("YYYY-MM-DD HH:mm:ss");
          }
          line[col.value] = displayValue;
        }

        // ハンドルキー設定
        // ハンドリングのために利用する値をリストデータに含める
        if (col.handleKey) {
          for (const key in col.handleKey) {
            let keyName = col.handleKey[key];
            if (col.handleConvertKey && keyName in col.handleConvertKey) {
              keyName = col.handleConvertKey[keyName];
            }
            let keyValue = "";
            // JOINデータ取得
            if (keyName.indexOf("___") > -1) {
              const colList = keyName.split("___");
              let isFirst = true;
              let keyData;
              for (const key of colList) {
                if (isFirst) {
                  keyData = row[key];
                  isFirst = false;
                } else if (keyData !== null) {
                  keyData = keyData[key];
                } else {
                  keyData = "";
                }
              }
              keyValue = keyData;
            } else if (keyName in row) {
              keyValue = row[keyName];
            }
            line[col.handleKey[key]] = keyValue;
          }
        }

        // reference
        const refName = col.reference;
        line[refName] = row[refName];
      }
      if (line) {
        line["id"] = row["id"];
        if (row["user"]) {
          line["user"] = row["user"];
        }
        listData.push(line);
        listKey[count] = row["id"];
        count++;
      }
    }
  }
  return { listData: listData, listKey: listKey };
};

export {
  setAuthFormDict,
  setFormDict,
  getFormAuthObject,
  getFormObject,
  settingDictionaryForms,
  setResponseApplyFormsDict,
  getApiParameters,
  setApiToFormsData,
  setApiToListData,
};
