const setting = {
  title: "契約情報",
  // apiAddress: "/contract/",
  apiAddress: "/contractavailabilityplanreadonly/",
  listSortBy: ["contract_code"],
  listSortDesc: [false],
  isListReadonly: true,
  showExpand: true,
  expandType: "contractinformation",
};

const forms = [];

const postFixedValue = [
  {
    column: "user",
    type: "props",
  },
];

const headers = [
  {
    text: "契約コード",
    value: "contract_code",
    align: "start",
  },
  {
    text: "区分",
    value: "order_status",
    align: "center",
  },
  {
    text: "プラン",
    value: "plan_name",
    align: "start",
  },
  {
    text: "サイト名称",
    value: "contract___site_name",
    align: "start",
  },
  {
    text: "サイト",
    value: "siteAddress",
    align: "center",
    sortable: false,
    handleKey: ["site_address"],
    handleConvertKey: { site_address: "contract___site_address" },
  },
  {
    text: "詳細",
    value: "data-table-expand",
    expandContent: true,
    align: "center",
    sortable: false,
  },
];

export default {
  setting,
  forms,
  postFixedValue,
  headers,
};
