<template>
  <v-app id="reportContract">
    <body class="report-body">
      <div
        v-for="(pageRow, key) in detailPage"
        :id="'report-page-' + key"
        :key="'report-page-' + key"
        class="report-page"
      >
        <header :id="'header_' + key">
          <div class="report-title-section-contract">
            <div class="report-title-contract">契約確認書</div>
          </div>
          <div class="report-receiver-section-contract">
            <div class="report-receiver-name-contract">
              {{ contractData.customer_name }}&nbsp;&nbsp;
              {{ contractData.person_in_charge }}&nbsp;様
            </div>
            <div class="report-receiver-address-contract">
              <span v-if="headerZipCode !== ''">{{ headerZipCode }}</span>
              {{ headerPrefecture }}
              {{ contractData.address_1 }}
              <span v-if="contractData.address_2 !== ''">
                {{ contractData.address_2 }}
              </span>
            </div>
          </div>
          <div v-if="key == 1">
            <div class="report-total-section-contract">
              <div class="report-total-label-contract">月額費用</div>
              <div class="report-total-price-contract">
                ¥{{
                  convertNumComma(
                    contractData.monthly_charge_after_discount_including_tax
                  )
                }}-
                <span class="report-total-tax-contract">（税込）</span>
              </div>
            </div>
            <div class="report-condition-section-contract">
              <div class="report-condition-label-contract">申込日</div>
              <div class="report-condition-value-contract">
                {{ contractData.order_date }}
              </div>
              <div
                class="report-condition-label-contract report-condition-date-contract"
              >
                課金開始月
              </div>
              <div
                class="report-condition-value-contract report-condition-date-contract"
              >
                {{ contractData.contract_month }}
              </div>
            </div>
            <div class="report-contract-code-section-contract">
              <div class="report-contract-code-contract">
                契約コード：{{ contractData.contract_code }}
              </div>
            </div>
          </div>
        </header>

        <section
          :id="'detail_' + key"
          :class="
            key == 1
              ? 'report-detail-section-contract'
              : 'report-detail-multiple-section-contract'
          "
        >
          <table :id="'table_' + key" class="report-detail-contract">
            <tbody>
              <tr v-for="(row, index) in pageRow" :key="'tr-detail-' + index">
                <th
                  v-if="row[0] === 'title'"
                  colspan="5"
                  class="report-detail-title-contract"
                  :key="'td-title-' + index"
                >
                  {{ row[1] }}
                </th>
                <td
                  v-else-if="row[0] === 'line'"
                  colspan="5"
                  class="report-detail-line-contract"
                  :key="'td-line-' + index"
                ></td>
                <td
                  v-else-if="row[0] === 'detail'"
                  v-for="(col, colIndex) in row[1]"
                  :class="col.class"
                  :key="'td-detail-' + index + '-' + colIndex"
                >
                  {{ col.data }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <div class="report-pagenumber-section-contract">
          <div class="report-pagenumber-contract">
            page {{ key }} / {{ detailPageCount }}
          </div>
        </div>
        <div class="report-logo-section-contract">
          <img
            class="report-logo-image"
            src="@/assets/images/mygenLogoPaper.svg"
          />
        </div>
      </div>
      <ReportFooter />
    </body>
  </v-app>
</template>

<script>
import store from "@/store";
import api from "@/services/api";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router/composables";
import { convertNumComma, getToday } from "@/utils/utilities.js";
import ReportFooter from "@/components/molecules/ReportFooter.vue";

export default defineComponent({
  name: "ReportContract",

  components: { ReportFooter },

  setup() {
    const route = useRoute();
    const customerId = computed(() => {
      return store.state.auth.customerId;
    });
    const isStaff = computed(() => {
      return store.state.auth.isStaff === true;
    });
    const isBrother = computed(() => {
      return store.state.auth.isBrother === true;
    });
    const contractPlanId = computed(() => {
      return route.params.id;
    });
    const dateTimeLabel = computed(() => {
      return getToday("jp-datetime");
    });

    const contractData = ref({});
    const headerZipCode = computed(() => {
      if (
        "zip_code" in contractData.value &&
        contractData.value.zip_code !== "0000000"
      ) {
        return (
          "〒" +
          contractData.value.zip_code.substr(0, 3) +
          "-" +
          contractData.value.zip_code.substr(3)
        );
      } else {
        return "";
      }
    });
    const headerPrefecture = computed(() => {
      if (
        "prefecture" in contractData.value &&
        contractData.value.prefecture === "その他"
      ) {
        return "";
      } else {
        return contractData.value.prefecture;
      }
    });
    const detailPage = ref({});
    const detailPageCount = ref(1);
    const contractTaxDisplay = ref(true);
    const isShibuya = ref(false);

    const setReportData = (res) => {
      const subDict1 = res["contract"];
      const subDict2 = subDict1["customer_reference"];
      const optionDict = res["contract_option"];

      // オプション設定
      const optionArray = [];
      for (const key in optionDict) {
        const row = optionDict[key];
        optionArray.push([
          "detail",
          [
            { data: row["option_name"], class: "report-detail-name-contract" },
            {
              data:
                row["option_default"] === true
                  ? "標準"
                  : "@" + convertNumComma(row["option_fee"]),
              class: "report-detail-price-contract",
            },
            {
              data:
                row["option_default"] === true
                  ? "x1"
                  : "x" + convertNumComma(row["option_quantity"]),
              class: "report-detail-quantity-contract",
            },
            {
              data: convertNumComma(row["option_charge"]) + "円",
              class: "report-detail-amount-contract",
            },
            {
              data:
                "税込 " +
                convertNumComma(row["option_charge_including_tax"]) +
                "円",
              class: "report-detail-tax-contract",
            },
          ],
        ]);
      }

      // GEN-COIN設定
      let coinArray = [];
      let coinData = {};
      if (res["contract_coin"]) {
        coinData = res["contract_coin"][0];
      }
      if (coinData !== undefined && coinData["coin_quantity"] !== 0) {
        coinArray = [
          ["title", "GEN-COIN"],
          ["line"],
          [
            "detail",
            [
              {
                data: "GEN-COIN",
                class: "report-detail-name-contract",
              },
              {
                data: "@" + convertNumComma(coinData["coin_rate"]),
                class: "report-detail-price-contract",
              },
              {
                data: "x" + convertNumComma(coinData["coin_quantity"]),
                class: "report-detail-quantity-contract",
              },
              {
                data: convertNumComma(res["total_coin_charge"]) + "円",
                class: "report-detail-amount-contract",
              },
              {
                data:
                  "税込 " +
                  convertNumComma(res["total_coin_charge_including_tax"]) +
                  "円",
                class: "report-detail-tax-contract",
              },
            ],
          ],
        ];
      }

      // クーポン設定
      let couponArray = [];
      let monthlyArray = [];
      let firstArray = [];
      let isCouponFirst = false;
      let isCouponMonthly = false;
      let isCouponLimitation = false;
      if (res["coupon_class"] === -10) {
        isCouponFirst = true;
      } else if (res["coupon_class"] === -20) {
        isCouponMonthly = true;
      } else if (res["coupon_class"] > 0) {
        isCouponLimitation = true;
      }
      if (isCouponFirst || isCouponMonthly || isCouponLimitation) {
        let discountCharge = res["discount_charge_of_first"];
        let discountChargeIncludingTax =
          res["discount_charge_of_first_including_tax"];
        let distountUnit = "x1";
        if (isCouponMonthly || isCouponLimitation) {
          discountCharge = res["discount_charge_of_monthly"];
          discountChargeIncludingTax =
            res["discount_charge_of_monthly_including_tax"];
          distountUnit = "毎月";
          if (res["coupon_class"] > 0) {
            distountUnit = res["coupon_class"] + "ヶ月";
          }
        }
        couponArray = [
          ["title", "クーポン"],
          ["line"],
          [
            "detail",
            [
              {
                data: res["coupon_name"],
                class: "report-detail-name-contract",
              },
              {
                data: "@" + convertNumComma(discountCharge),
                class: "report-detail-price-contract",
              },
              {
                data: distountUnit,
                class: "report-detail-quantity-contract",
              },
              {
                data: convertNumComma(discountCharge) + "円",
                class: "report-detail-amount-contract",
              },
              {
                data:
                  "税込 " + convertNumComma(discountChargeIncludingTax) + "円",
                class: "report-detail-tax-contract",
              },
            ],
          ],
        ];
        if (isCouponMonthly || isCouponLimitation) {
          // 月額料金（クーポン適用後）
          monthlyArray = [
            [
              "detail",
              [
                {
                  data: "月額料金（クーポン適用）",
                  class: "report-detail-name-contract",
                },
                {
                  data: "",
                  class: "report-detail-price-contract",
                },
                {
                  data: "",
                  class: "report-detail-quantity-contract",
                },
                {
                  data:
                    convertNumComma(res["monthly_charge_after_discount"]) +
                    "円",
                  class: "report-detail-amount-contract",
                },
                {
                  data:
                    "税込 " +
                    convertNumComma(
                      res["monthly_charge_after_discount_including_tax"]
                    ) +
                    "円",
                  class: "report-detail-tax-contract",
                },
              ],
            ],
          ];
        } else if (isCouponFirst) {
          // 初期化（新規登録ベース）
          let firstChargeLabel = "初期費用（クーポン適用）";
          let firstCharge = res["first_charge_after_discount"];
          let firstChargeIncludingTax =
            res["first_charge_after_discount_including_tax"];
          if (
            res["order_type"] === 20 ||
            res["order_type"] === 21 ||
            res["order_type"] === 22
          ) {
            // 契約変更の時
            firstChargeLabel = "変更費用（クーポン適用）";
            firstCharge = res["change_charge_after_discount"];
            firstChargeIncludingTax =
              res["change_charge_after_discount_including_tax"];
          }
          firstArray = [
            [
              "detail",
              [
                {
                  data: firstChargeLabel,
                  class: "report-detail-name-contract",
                },
                {
                  data: "",
                  class: "report-detail-price-contract",
                },
                {
                  data: "",
                  class: "report-detail-quantity-contract",
                },
                {
                  data: convertNumComma(firstCharge) + "円",
                  class: "report-detail-amount-contract",
                },
                {
                  data:
                    "税込 " + convertNumComma(firstChargeIncludingTax) + "円",
                  class: "report-detail-tax-contract",
                },
              ],
            ],
          ];
        }
      }

      // 契約データ生成
      contractData.value = Object.assign(
        Object.assign(res, subDict1),
        subDict2
      );
      contractData.value["order_date"] =
        contractData.value["order_date"].replace("-", "年").replace("-", "月") +
        "日";
      const contractDate = contractData.value["contract_date"].split("-");
      contractData.value["contract_month"] =
        contractDate[0] + "年" + contractDate[1] + "月";

      // 明細データ生成
      const detailData = [
        ["title", "契約プラン"],
        ["line"],
        [
          "detail",
          [
            { data: res["plan_name"], class: "report-detail-name-contract" },
            {
              data: "@" + convertNumComma(res["basic_charge"]),
              class: "report-detail-price-contract",
            },
            { data: "x1", class: "report-detail-quantity-contract" },
            {
              data: convertNumComma(res["basic_charge"]) + "円",
              class: "report-detail-amount-contract",
            },
            {
              data:
                "税込 " +
                convertNumComma(res["basic_charge_including_tax"]) +
                "円",
              class: "report-detail-tax-contract",
            },
          ],
        ],
        ["title", "アカウント数"],
        ["line"],
        [
          "detail",
          [
            { data: "アカウント", class: "report-detail-name-contract" },
            {
              data: "@" + convertNumComma(res["account_price"]),
              class: "report-detail-price-contract",
            },
            {
              data: "x" + convertNumComma(res["account_quantity"]),
              class: "report-detail-quantity-contract",
            },
            {
              data: convertNumComma(res["total_account_charge"]) + "円",
              class: "report-detail-amount-contract",
            },
            {
              data:
                "税込 " +
                convertNumComma(res["total_account_charge_including_tax"]) +
                "円",
              class: "report-detail-tax-contract",
            },
          ],
        ],
        ["title", "オプション"],
        ["line"],
      ]
        .concat(optionArray)
        .concat([
          ["title", "サポート料金"],
          ["line"],
          [
            "detail",
            [
              { data: "GMSPプログラム", class: "report-detail-name-contract" },
              {
                data: res["support_rate"] + "%",
                class: "report-detail-price-contract",
              },
              { data: "x1", class: "report-detail-quantity-contract" },
              {
                data: convertNumComma(res["support_charge"]) + "円",
                class: "report-detail-amount-contract",
              },
              {
                data:
                  "税込 " +
                  convertNumComma(res["support_charge_including_tax"]) +
                  "円",
                class: "report-detail-tax-contract",
              },
            ],
          ],
        ])
        .concat(coinArray)
        .concat([
          ["title", "設定費用"],
          ["line"],
          [
            "detail",
            [
              {
                data:
                  res["order_type"] === 10 ? "初回設定費用" : "設定変更費用",
                class: "report-detail-name-contract",
              },
              { data: "", class: "report-detail-price-contract" },
              { data: "", class: "report-detail-quantity-contract" },
              {
                data:
                  (res["order_type"] === 10
                    ? convertNumComma(res["first_commission_charge"])
                    : convertNumComma(res["change_commission_charge"])) + "円",
                class: "report-detail-amount-contract",
              },
              {
                data:
                  "税込 " +
                  (res["order_type"] === 10
                    ? convertNumComma(
                        res["first_commission_charge_including_tax"]
                      )
                    : convertNumComma(
                        res["change_commission_charge_including_tax"]
                      )) +
                  "円",
                class: "report-detail-tax-contract",
              },
            ],
          ],
        ])
        .concat(couponArray)
        .concat([
          ["title", "お支払い金額"],
          ["line"],
          [
            "detail",
            [
              { data: "月額料金", class: "report-detail-name-contract" },
              {
                data: "",
                class: "report-detail-price-contract",
              },
              { data: "", class: "report-detail-quantity-contract" },
              {
                data: convertNumComma(res["monthly_charge"]) + "円",
                class: "report-detail-amount-contract",
              },
              {
                data:
                  "税込 " +
                  convertNumComma(res["monthly_charge_including_tax"]) +
                  "円",
                class: "report-detail-tax-contract",
              },
            ],
          ],
        ])
        .concat(monthlyArray)
        .concat([
          [
            "detail",
            [
              {
                data: res["order_type"] === 10 ? "初期費用" : "変更費用",
                class: "report-detail-name-contract",
              },
              {
                data: "",
                class: "report-detail-price-contract",
              },
              { data: "", class: "report-detail-quantity-contract" },
              {
                data:
                  (res["order_type"] === 10
                    ? convertNumComma(res["first_charge"])
                    : convertNumComma(res["change_charge"])) + "円",
                class: "report-detail-amount-contract",
              },
              {
                data:
                  "税込 " +
                  (res["order_type"] === 10
                    ? convertNumComma(res["first_charge_including_tax"])
                    : convertNumComma(res["change_charge_including_tax"])) +
                  "円",
                class: "report-detail-tax-contract",
              },
            ],
          ],
        ])
        .concat(firstArray);

      const detailFirstCount = 18; // 1ページ目は明細18行
      const detailSecondCount = 25; // 2ページ目以降は明細25行
      let lineCount = 0;
      let isFirst = true;
      let tempPageCount = 1;
      const tempDetailPage = {};
      // detailData は配列（辞書型ではない）なので for of でループ処理
      for (const line of detailData) {
        lineCount++;
        if (isFirst && lineCount % detailFirstCount === 0) {
          // 1ページ目の明細行数
          isFirst = false;
          tempPageCount++;
        } else if (
          tempPageCount === 1 &&
          lineCount >= detailFirstCount - 1 &&
          lineCount <= detailFirstCount &&
          (line[0] === "title" || line[0] === "line")
        ) {
          lineCount++;
          if (lineCount <= detailFirstCount) {
            lineCount++;
          }
          tempPageCount++;
        } else if (
          !isFirst &&
          (lineCount - detailFirstCount) % detailSecondCount === 0
        ) {
          // 2ページ以降の明細行数
          tempPageCount++;
        }
        if (tempDetailPage[tempPageCount]) {
          tempDetailPage[tempPageCount].push(line);
        } else {
          tempDetailPage[tempPageCount] = [line];
        }
      }
      detailPage.value = tempDetailPage;
      detailPageCount.value = tempPageCount;
    };

    /**
     * api:データ取得
     */
    const loadData = () => {
      let address = "/contractplanbase/" + contractPlanId.value + "/";
      if (isStaff.value === false && isBrother.value === false) {
        address += "?customer=" + customerId.value;
      }

      api({
        method: "get",
        url: address,
      }).then((response) => {
        const res = response.data;
        setReportData(res);
      });
    };

    // データ取得
    loadData();

    return {
      convertNumComma,
      customerId,
      isStaff,
      isBrother,
      contractPlanId,
      dateTimeLabel,
      contractTaxDisplay,
      isShibuya,

      contractData,
      headerZipCode,
      headerPrefecture,
      detailPage,
      detailPageCount,
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/report.scss";
</style>
