var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$vuetify.breakpoint.xs ? 'pa-5' : 'px-16 py-10'},[_c('v-container',{class:_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? '' : 'px-16 py-10'},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DivTitle',{key:'title-' + _vm.formKey,attrs:{"propsTitle":_vm.listTitle,"propsComment":_vm.titleComment,"propsIsTerms":true}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ButtonCommon',{attrs:{"propsLabel":"新規申込","propsClick":_vm.clickCreateOrder}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ButtonCommon',{attrs:{"propsLabel":"簡単見積","propsClick":_vm.clickCreateEstimate}})],1),_c('v-spacer')],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.mainData),function(row,index){return _c('div',{key:index},[_c('v-card',{staticClass:"mygenCard mt-5 pa-3",attrs:{"width":"100%","rounded":"lg"}},[_c('v-card-text',{staticClass:"mygenText--text"},[_c('v-row',[_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(row["plan_name"])+" ")]),_c('v-spacer'),(
                    _vm.isChangeOrder(
                      row['order_status'],
                      row['is_revision'],
                      row['is_sub_data'],
                      row['is_contract_cancel'],
                      row['is_contract_date_after']
                    ) && false
                  )?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"mygen-button font-weight-bold",attrs:{"block":"","dark":"","rounded":"","elevation":"4"},on:{"click":function($event){return _vm.clickChangeAccountOrder(index)}}},[_vm._v(" "+_vm._s(_vm.buttonLabelAccount)+" ")])],1):_vm._e(),(
                    _vm.isChangeOrder(
                      row['order_status'],
                      row['is_revision'],
                      row['is_sub_data'],
                      row['is_contract_cancel'],
                      row['is_contract_date_after']
                    )
                  )?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"mygen-button font-weight-bold",attrs:{"block":"","dark":"","rounded":"","elevation":"4"},on:{"click":function($event){return _vm.clickChangeOrder(index)}}},[_vm._v(" "+_vm._s(_vm.buttonLabelChange)+" ")])],1):_vm._e(),(
                    _vm.isChangeOrder(
                      row['order_status'],
                      row['is_revision'],
                      row['is_sub_data'],
                      row['is_contract_cancel'],
                      row['is_contract_date_after']
                    )
                  )?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"block":"","color":"primary","outlined":"","rounded":""},on:{"click":function($event){return _vm.clickContractCancel(index)}}},[_vm._v(" "+_vm._s(_vm.buttonLabelCancel)+" ")])],1):_vm._e()],1)],1),(row['order_status'] === 50)?_c('v-card-text',{staticClass:"my-0 py-0"},[_c('ChipStatus',{staticClass:"mr-3",attrs:{"propsType":'customer_order_status',"propsValue":row['order_status']}}),_c('v-btn',{staticClass:"primary--text",staticStyle:{"text-transform":"none"},attrs:{"text":"","plain":"","href":row['site_address'],"target":"_blank"}},[_vm._v(" "+_vm._s(row["site_address"])+" ")])],1):(row['order_status'] === 70)?_c('v-card-text',{staticClass:"my-0 py-0"},[_c('ChipStatus',{staticClass:"mr-3",attrs:{"propsType":'customer_order_status',"propsValue":row['order_status']}}),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"text":"","plain":"","href":row['site_address'],"target":"_blank"}},[_vm._v(" "+_vm._s(row["site_address"])+" ")])],1):_c('v-card-text',{staticClass:"my-0 py-0"},[_c('ChipStatus',{staticClass:"mr-3",attrs:{"propsType":'customer_order_status',"propsValue":0}}),(
                  row['order_status'] === 10 || row['order_status'] === 20
                )?_c('ChipStatus',{staticClass:"mr-3",attrs:{"propsType":'customer_order_status',"propsValue":row['order_status']}}):_vm._e()],1),_c('v-card-actions',[(row['order_status'] === 50 || row['order_status'] == 70)?_c('v-row',{staticClass:"pl-3 mygenText--text text-caption"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_vm._v(" 契約コード : "+_vm._s(row["contract_code"])+" ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_vm._v(" 契約開始日 : "+_vm._s(row["contract_date"])+" ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_vm._v(" サイト名称 : "+_vm._s(row["site_name"])+" ")])],1):_c('v-row',{staticClass:"px-3 py-5 text-caption"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_vm._v(" 申込コード : "+_vm._s(row["order_code"])+" ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_vm._v(" 申込日 : "+_vm._s(row["order_date"])+" ")]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_vm._v(" サイト名称 : "+_vm._s(row["site_name"])+" ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeShowOrderMain(index)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showOrderMain[index] ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOrderMain[index]),expression:"showOrderMain[index]"}]},[_c('v-card-text',[_c('ContainerContractDetail',{key:'table-' + _vm.formKey + '-' + row['id'],attrs:{"propsData":row,"propsShowCharge":row['order_status'] <= 50}})],1),(row['order_status'] == 50)?_c('v-card-text',{attrs:{"pb-5":""}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"4","sm":"4"}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                          name: 'reportContract',
                          params: { id: row['id'] },
                        },"target":"_blank"}},[_c('ButtonCommon',{attrs:{"propsLabel":"契約確認書"}})],1)],1),_c('v-spacer')],1)],1):_vm._e()],1)]),(row['is_contract_cancel'])?_c('v-card-text',{staticClass:"my-0 py-0"},[_c('ChipStatus',{staticClass:"mr-3",attrs:{"propsType":'cancel_order_status_for_customer',"propsValue":row['contract_cancel']['cancel_order_status'] < 50 ? 0 : 50}})],1):(row['is_sub_data'])?_c('v-card-text',{staticClass:"my-0 py-0"},[_c('ChipStatus',{staticClass:"mr-3",attrs:{"propsType":'customer_order_status_change',"propsValue":row['sub_data']['order_status'] < 50 ? 0 : 50}}),(
                  row['sub_data']['order_status'] === 10 ||
                  row['sub_data']['order_status'] === 20
                )?_c('ChipStatus',{staticClass:"mr-3",attrs:{"propsType":'customer_order_status_change',"propsValue":row['sub_data']['order_status']}}):_vm._e()],1):_vm._e(),(row['is_contract_cancel'])?_c('v-card-actions',[_c('div',{staticClass:"pl-3 text-caption"},[_c('span',{staticClass:"mr-5"},[_vm._v(" 申込コード : "+_vm._s(row["contract_cancel"]["cancel_order_code"])+" ")]),_c('span',{staticClass:"mr-5"},[_vm._v(" 申込日 : "+_vm._s(row["contract_cancel"]["cancel_order_date"])+" ")]),(
                    row['is_contract_cancel'] && row['contract_cancel_date']
                  )?_c('span',[_vm._v(" 契約満了日 : "+_vm._s(row["contract_cancel_date"])+" ")]):_vm._e()])]):(row['is_sub_data'])?_c('v-card-actions',[(row['sub_data']['order_status'] === 50)?_c('div',{staticClass:"pl-3 text-caption"},[_c('span',{staticClass:"mr-5"},[_vm._v(" 契約コード : "+_vm._s(row["sub_data"]["contract_code"])+" ")]),_c('span',[_vm._v(" 契約開始日 : "+_vm._s(row["sub_data"]["contract_date"])+" ")])]):_c('div',{staticClass:"pl-3 text-caption"},[_c('span',{staticClass:"mr-5"},[_vm._v(" 申込コード : "+_vm._s(row["sub_data"]["order_code"])+" ")]),_c('span',[_vm._v("申込日 : "+_vm._s(row["sub_data"]["order_date"])+" ")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeShowOrderSub(row['sub_data']['id'])}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showOrderSub[row["sub_data"]["id"]] ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1):_vm._e(),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showOrderSub[row['sub_data']['id']]),expression:"showOrderSub[row['sub_data']['id']]"}]},[_c('v-divider'),_c('v-card-text',[_c('ContainerContractDetail',{key:'table-sub-' + _vm.formKey + '-' + row['id'],attrs:{"propsData":row['sub_data'],"propsChange":true,"propsShowCharge":true}}),(
                      row['sub_data']['order_account_comment'] &&
                      row['sub_data']['order_account_comment'].length > 0
                    )?_c('ContainerOrderComment',{key:'comment-account-' + _vm.formKey + '-' + row['id'],attrs:{"propsComment":row['sub_data']['order_account_comment'],"propsIsOption":false,"propsIsCard":true}}):_vm._e(),(
                      row['sub_data']['order_option_comment'] &&
                      row['sub_data']['order_option_comment'].length > 0
                    )?_c('ContainerOrderComment',{key:'comment-option-' + _vm.formKey + '-' + row['id'],attrs:{"propsComment":row['sub_data']['order_option_comment'],"propsIsOption":true,"propsIsCard":true}}):_vm._e()],1)],1)])],1)],1)}),(_vm.isData === false)?_c('div',[_c('v-card',{staticClass:"mygenCard mt-5 pa-5",attrs:{"flat":"","width":"100%","rounded":"lg"}},[_c('v-card-text',{staticClass:"mygenText--text"},[_vm._v(" 契約中のサービスは存在しません ")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","no-click-animation":"","fullscreen":_vm.$vuetify.breakpoint.xs},model:{value:(_vm.dialogAccount),callback:function ($$v) {_vm.dialogAccount=$$v},expression:"dialogAccount"}},[_c('v-card',{attrs:{"rounded":"lg"}},[(_vm.dialogAccount)?_c('FormContractAccountOrder',{key:'form-account-order-' + _vm.formKey,attrs:{"propsEditId":_vm.childEditId},on:{"close":_vm.closeDialog}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"persistent":"","no-click-animation":"","fullscreen":_vm.$vuetify.breakpoint.xs},model:{value:(_vm.dialogOrder),callback:function ($$v) {_vm.dialogOrder=$$v},expression:"dialogOrder"}},[_c('v-card',[(_vm.dialogOrder)?_c('FormContractOrder',{key:'form-order-' + _vm.formKey,attrs:{"propsFormMode":_vm.formMode,"propsEditId":_vm.childEditId},on:{"close":_vm.closeDialog}}):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"1080","persistent":"","no-click-animation":"","fullscreen":_vm.$vuetify.breakpoint.xs},model:{value:(_vm.dialogCancel),callback:function ($$v) {_vm.dialogCancel=$$v},expression:"dialogCancel"}},[_c('v-card',[(_vm.dialogCancel)?_c('FormContractCancel',{key:'form-cancel-' + _vm.formKey,attrs:{"propsFormMode":_vm.formMode,"propsEditId":_vm.childEditId},on:{"close":_vm.closeDialog}}):_vm._e()],1)],1)],2)],1)],1),(_vm.progress)?_c('OverlayProgress'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }